/**
 * Split tests to use in the app
 * Can be passed as a string (name) or an object with configuration
 * See defaults in split-test duck for default variants and other available options
 * @type {String|Array.Object}
 */

module.exports = [
  'productRecommendationsV2', // Test 28
  'PnmPopupProductDetails', // Test 349
  'MobileTrustpilotCarousel', // Test 377
  'MobileProductDetails', // Test 366
  'MobileCategoryPageScrollBreak', // Test 378
];

import React, { Suspense, lazy } from 'react';
import { IntlProvider, useIntl, defineMessages } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Router, Switch } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  faSlidersH,
  faBars,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faSpinner,
  faRssSquare,
  faHeart,
  faStar,
  faGamepad,
  faMouse,
  faHeadset,
  faTimesCircle,
  faCheckCircle,
  faCheck,
  faLock,
  faLink,
  faSync,
  faExclamationTriangle,
  faExclamation,
  faSearch,
  faSearchPlus,
  faRocket,
  faExternalLinkAlt,
  faShoppingCart,
  faCartPlus,
  faShoppingBasket,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faInfo,
  faUser,
  faCog,
  faBookOpen,
  faQuestionCircle,
  faTrashAlt,
  faMagic,
  faArrowCircleRight,
  faArrowAltCircleRight,
  faArrowLeft,
  faArrowRight,
  faGrin,
  faTag,
  faGraduationCap,
  faHeadphonesAlt,
  faLaptopCode,
  faLaptop,
  faGlobeEurope,
  faGlobeAmericas,
  faCouch,
  faMale,
  faHome,
  faThermometerEmpty,
  faVrCardboard,
  faSignOutAlt,
  faLightbulb,
  faKey,
  faCashRegister,
  faPlus,
  faHandHoldingHeart,
  faGift,
  faTh,
  faThList,
  faUserFriends,
  faCalendarAlt,
  faStarHalf,
  faCreditCard,
  faEnvelope,
  faThumbsUp,
  faThumbsDown,
  faFilter,
  faMoneyBillAlt,
  faMoneyCheckAlt,
  faCertificate,
  faDollarSign,
  faFileDownload,
  faVolumeUp,
  faVolumeOff,
  faBell,
  faEdit,
  faAward,
  faTrophy,
  faTicketAlt,
  faCrosshairs,
  faDragon,
  faUsers,
  faFistRaised,
  faCar,
  faFighterJet,
  faHatWizard,
  faGhost,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
  faSteam,
  faTwitch,
  faWindows,
  faApple,
  faLinux,
  faChrome,
  faFirefox,
  faDiscord,
  faAndroid,
  faWhatsapp,
  faTelegramPlane,
} from '@fortawesome/free-brands-svg-icons';
import {
  faClock,
  faHeart as faHeartO,
  faBell as faBellO,
  faPlayCircle,
} from '@fortawesome/free-regular-svg-icons';

import { history } from '../../redux/store';
import isBot from '../../Helpers/SEO/is-bot';
// import { fetchSiteBrand } from '../../redux/ducks/site-brand';
import AnonymousId from '../../containers/Auth/AnonymousId';
import ScrollToTop from '../../containers/Utility/ScrollToTop';
import NavBar from '../../containers/NavBar/NavBar';
import ErrorBoundary from '../Utility/ErrorBoundary';
import ModalGroupContainer from '../../containers/App/ModalGroupContainer';
import FeedbackPopupContainer from '../../containers/Utility/FeedbackPopups/FeedbackPopupContainer';
import FooterGroupContainer from '../../containers/App/FooterGroupContainer';
import Route from '../../containers/App/Route';
import Meta from '../Utility/SEO/Meta';
import SkeletonPlaceholder from '../Utility/SkeletonPlaceholder';
import ViewportProvider from './ViewportProvider';
import VisibleVoucherContainer from '../../components/Vouchers/VisibleVoucherContainer';
import GeneralUtils from '../../Helpers/GeneralUtils';
import CookieConsentWrapper from '../../components/Cookie/CookieConsentWrapper';

const locale = window.locale || 'en';

library.add(
  faFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
  faSteam,
  faTwitch,
  faWindows,
  faApple,
  faLinux,
  faChrome,
  faFirefox,
  faDiscord,
  faAndroid,
  faWhatsapp,
  faBars,
  faSlidersH,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faSpinner,
  faRssSquare,
  faStar,
  faGamepad,
  faMouse,
  faHeadset,
  faHeart,
  faHeartO,
  faBellO,
  faTimesCircle,
  faCheckCircle,
  faCheck,
  faLock,
  faLink,
  faSync,
  faExclamationTriangle,
  faExclamation,
  faSearch,
  faSearchPlus,
  faRocket,
  faShoppingCart,
  faCartPlus,
  faShoppingBasket,
  faExternalLinkAlt,
  faCog,
  faBookOpen,
  faQuestionCircle,
  faTrashAlt,
  faMagic,
  faArrowCircleRight,
  faArrowAltCircleRight,
  faArrowLeft,
  faArrowRight,
  faGrin,
  faClock,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faInfo,
  faUser,
  faTag,
  faGraduationCap,
  faHeadphonesAlt,
  faGlobeEurope,
  faGlobeAmericas,
  faCouch,
  faMale,
  faHome,
  faThermometerEmpty,
  faVrCardboard,
  faLightbulb,
  faSignOutAlt,
  faKey,
  faCashRegister,
  faPlayCircle,
  faPlus,
  faHandHoldingHeart,
  faGift,
  faTh,
  faThList,
  faUserFriends,
  faCalendarAlt,
  faStarHalf,
  faCreditCard,
  faEnvelope,
  faThumbsUp,
  faThumbsDown,
  faFilter,
  faMoneyBillAlt,
  faMoneyCheckAlt,
  faCertificate,
  faLaptopCode,
  faLaptop,
  faDollarSign,
  faFileDownload,
  faVolumeUp,
  faVolumeOff,
  faBell,
  faEdit,
  faAward,
  faTrophy,
  faTicketAlt,
  faTelegramPlane,
  faCrosshairs,
  faDragon,
  faUsers,
  faFistRaised,
  faCar,
  faFighterJet,
  faHatWizard,
  faGhost,
  faWrench,
);

const { translations } = window;


const messages = defineMessages({
  desc: {
    id: 'home.meta.desc',
    defaultMessage: 'Get massive savings on exclusive Steam bundles and great PC, Mac, and Linux games - updated daily. Join our community of over 2.6 million gamers - explore Fanatical now!',
  },
  title: {
    id: 'home.meta.title',
    defaultMessage: 'Buy PC Games, Steam Keys, Game Bundles',
  },
});

const { isSaleLive } = GeneralUtils.getSaleData();

const HomePageContainer = lazy(() => import(/* webpackChunkName: "HomePage" */'../../containers/HomePage/HomePageContainer'));

const AboutUsPage = lazy(() => import(/* webpackChunkName: "AboutUs" */'../AboutUs/AboutUs'));

const AffiliatesPage = lazy(() => import(/* webpackChunkName: "Affiliates" */'../Affiliates/Affiliates'));

const ExtensionPage = lazy(() => import(/* webpackChunkName: "BrowserExtension" */'../BrowserExtension/ExtensionPage'));
const ExtensionUninstallPage = lazy(() => import(/* webpackChunkName: "BrowserExtension" */'../BrowserExtension/ExtensionUninstallPage'));

const RedeemCodeContainer = lazy(() => import(/* webpackChunkName: "RedeemCode" */'../../containers/RedeemCode/RedeemCodeContainer'));

const CommunityPage = lazy(() => import(/* webpackChunkName: "Pages" */'../Community/Community'));
const CookiePolicy = lazy(() => import(/* webpackChunkName: "Pages" */'../../components/CookiePolicy/CookiePolicy'));
const NotFound = lazy(() => import(/* webpackChunkName: "Pages" */'../NotFound/NotFound'));
const RedeemGiftContainer = lazy(() => import(/* webpackChunkName: "Pages" */'../../containers/Gift/RedeemGiftContainer'));
const SplitTestSwitch = lazy(() => import(/* webpackChunkName: "Pages" */'../SplitTestSwitch/SplitTestSwitch'));
const VouchersContainer = lazy(() => import(/* webpackChunkName: "Pages" */'../../containers/Vouchers/VouchersContainer'));
const ReferralsPage = lazy(() => import(/* webpackChunkName: "Pages" */'../ReferFriend/ReferralPage'));

const HpOmenContainer = lazy(() => import(/* webpackChunkName: "HPOmen" */'../../containers/Partners/HpOmenContainer'));
const HpOmenOasis = lazy(() => import(/* webpackChunkName: "HPOmen" */'../../components/Partners/HpOmenOasis/HpOmenOasis'));
const HpOmenGamingHub = lazy(() => import(/* webpackChunkName: "HPOmen" */'../../components/Partners/HpOmenGamingHub/HpOmenGamingHub'));

const Pickaxe = lazy(() => import(/* webpackChunkName: "HPOmen" */'../../components/Partners/Pickaxe/Pickaxe'));

const WelcomePage = lazy(() => import(/* webpackChunkName: "HPOmen" */'../../components/Welcome/Welcome'));
const WelcomeNewUserPage = lazy(() => import(/* webpackChunkName: "HPOmen" */'../../components/Affiliates/WelcomeNewUser'));

const AccountConfirmContainer = lazy(() => import(/* webpackChunkName: "Account" */'../../containers/AccountConfirm/AccountConfirmContainer'));
const EmailChangeConfirmContainer = lazy(() => import(/* webpackChunkName: "Account" */'../../containers/EmailChangeConfirm/EmailChangeConfirmContainer'));
const MagicLoginContainer = lazy(() => import(/* webpackChunkName: "Account" */'../../containers/Auth/MagicLoginContainer'));
const PasswordResetContainer = lazy(() => import(/* webpackChunkName: "Account" */'../../containers/PasswordReset/PasswordResetContainer'));
const WishlistContainer = lazy(() => import(/* webpackChunkName: "Account" */'../../containers/Wishlist/WishlistContainer'));
const WishlistSyncContainer = lazy(() => import(/* webpackChunkName: "Account" */'../../containers/Wishlist/WishlistSyncContainer'));
const EpicReturnContainer = lazy(() => import(/* webpackChunkName: "Account" */'../../containers/Epic/EpicReturnContainer'));

const AccountRoute = lazy(() => import(/* webpackChunkName: "AccountArea" */'../../containers/App/AccountRoute'));
const AccountOverviewContainer = lazy(() => import(/* webpackChunkName: "AccountArea" */'../../containers/Account/AccountOverviewContainer'));
const LoginAndSecurity = lazy(() => import(/* webpackChunkName: "AccountArea" */'../../components/Account/LoginAndSecurity/LoginAndSecurity'));
const LinkedAccounts = lazy(() => import(/* webpackChunkName: "AccountArea" */'../../components/Account/LinkedAccounts/LinkedAccounts'));
const ContactPreferences = lazy(() => import(/* webpackChunkName: "AccountArea" */'../Account/ContactPreferences/ContactPreferences'));
const WebsiteSettings = lazy(() => import(/* webpackChunkName: "AccountArea" */'../Account/WebsiteSettings/WebsiteSettings'));
const PaymentInformation = lazy(() => import(/* webpackChunkName: "AccountArea" */'../Account/PaymentInformation/PaymentInformation'));
const OrdersAndKeys = lazy(() => import(/* webpackChunkName: "AccountArea" */'../Account/OrdersAndKeys/OrdersAndKeys'));
const OrderContainer = lazy(() => import(/* webpackChunkName: "AccountArea" */'../../containers/Order/OrderContainer'));
const Support = lazy(() => import(/* webpackChunkName: "AccountArea" */'../Account/Support/Support'));
const PreferenceCenterContainer = lazy(() => import(/* webpackChunkName: "AccountArea" */'../../containers/Account/PreferenceCenterContainer'));
const CouponsAndRewards = lazy(() => import(/* webpackChunkName: "AccountArea" */'../Account/CouponsAndRewards/CouponsAndRewards'));
const KeyLibrary = lazy(() => import(/* webpackChunkName: "AccountArea" */'../../components/Account/ProductLibrary/KeyLibrary'));
const OrderItemReviewForm = lazy(() => import(/* webpackChunkName: "AccountArea" */'../ProductReviews/OrderItemReviews/OrderItemReviewForm'));
const OrderItemReviewsContainer = lazy(() => import(/* webpackChunkName: "AccountArea" */'../../containers/ProductReviews/OrderItemReviewsContainer'));

const FacetedFacetSearch = lazy(() => import(/* webpackChunkName: "FacetedFacetSearch" */'../FacetedFacetSearch/FacetedFacetSearch'));
const FacetOrderedList = lazy(() => import(/* webpackChunkName: "FacetSearch" */'../FacetSearch/FacetOrderedList'));
const FacetSearch = lazy(() => import(/* webpackChunkName: "FacetSearch" */'../FacetSearch/FacetSearch'));

const GamesLike = lazy(() => import(/* webpackChunkName: "GamesLike" */'../FacetSearch/GamesLike'));

const AllGames = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/AllGames'));
const AllDLC = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/AllDLC'));
const UpcomingGames = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/UpcomingGames'));
const NewReleases = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/NewReleases'));
const Released = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/Released'));
const FlashDeals = lazy(() => import(/* webpackChunkName: "FlashDeals" */'../../containers/FlashSales/FlashDeals'));
const BundlesContainer = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/Bundle/BundlesContainer'));
const BundlefestContainer = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/Bundle/BundlefestContainer'));
const EndingSoonContainer = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/EndingSoon'));
const LatestDealsContainer = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/LatestDeals'));
const FreeToPlayContainer = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/FreeToPlay'));
const MostWantedContainer = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/MostWanted/MostWantedContainer'));
const PlatformLinux = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/PlatformLinux'));
const PlatformMac = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/PlatformMac'));
const GamesUnder = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/GamesUnder'));
const SteamDeck = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/SteamDeck'));
const XboxGames = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/XboxGames'));
const RecentlyViewedPage = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../components/RecentlyViewed/RecentlyViewedPage'));
const TopSellers = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../components/TopSellers/TopSellers'));
const TrendingDealsContainer = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/TrendingDeals'));
const MostGiftedContainer = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/MostGiftedContainer'));
const NotOnSale = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/NotOnSale'));
const DollarDealsContainer = lazy(() => import(/* webpackChunkName: "DollarDeals" */'../../containers/DollarDeals/DollarDealsContainer'));
const GiftCards = lazy(() => import(/* webpackChunkName: "SearchPages" */'../../containers/StaticSearch/GiftCards'));
const FantasyVersePage = lazy(() => import(/* webpackChunkName: "SearchPages" */'../FantasyVerse/FantasyVersePage'));

const OnSaleSectionsContainer = lazy(() => import(/* webpackChunkName: "OnSale" */'../../containers/OnSale/OnSaleSectionsContainer'));
const SummerSaleContainer = lazy(() => import(/* webpackChunkName: "OnSale" */'../../containers/OnSale/SummerSaleContainer'));
const CyberMonday = lazy(() => import(/* webpackChunkName: "OnSale" */'../Templates/CyberMonday'));
let BlackFriday = lazy(() => import(/* webpackChunkName: "OnSale" */'../Templates/BlackFriday'));
if (isSaleLive) {
  BlackFriday = lazy(() => import(/* webpackChunkName: "OnSale" */'../Sales/BlackFridaySale'));
}

const HallOfFameContainer = lazy(() => import(/* webpackChunkName: "OnSale" */'../../components/HallOfFame/HallOfFameContainer'));

const GameBotContainer = lazy(() => import(/* webpackChunkName: "GameBot" */'../../containers/GameBot/GameBotContainer'));

const SearchPage = lazy(() => import(/* webpackChunkName: "InstantSearch" */'../../components/Search/Search'));

const GiftGuide = lazy(() => import(/* webpackChunkName: "GiftGuide" */'../Gift/GiftGuide'));

const BlogPostContainer = lazy(() => import(/* webpackChunkName: "Blog" */'../../containers/Blog/BlogPostContainer'));
const BlogPostsContainer = lazy(() => import(/* webpackChunkName: "Blog" */'../../containers/Blog/BlogPostsContainer'));
const BlogPreviewContainer = lazy(() => import(/* webpackChunkName: "Blog" */'../../containers/Blog/BlogPreviewContainer'));

const Cart = lazy(() => import(/* webpackChunkName: "Cart" */'../../components/Checkout/Checkout'));

const ReceiptContainer = lazy(() => import(/* webpackChunkName: "Receipt" */'../../containers/Receipt/ReceiptContainer'));

const PickAndMixContainer = lazy(() => import(/* webpackChunkName: "ProductPage" */'../../containers/PickAndMix/PickAndMixContainer'));

const ProductContainer = lazy(() => import(/* webpackChunkName: "ProductPage" */'../../containers/Product/ProductContainer'));

const ProductDLCContainer = lazy(() => import(/* webpackChunkName: "ProductDLC" */'../../containers/Product/SubPages/ProductDLCContainer'));

const ResetPassword = lazy(() => import(/* webpackChunkName: "ResetPassword" */'../Auth/ResetPassword'));

const Payment = lazy(() => import(/* webpackChunkName: "Payment" */'../../components/Payment/Payment'));
const PaymentReturnContainer = lazy(() => import(/* webpackChunkName: "Payment" */'../../containers/Payment/PaymentReturnContainer'));

const BillingContainer = lazy(() => import(/* Billing */'../../containers/Billing/BillingContainer'));

const OptinContainer = lazy(() => import(/* webpackChunkName: "Account" */'../../containers/Optin/OptinContainer'));
const ImagePreviewContainer = lazy(() => import(/* webpackChunkName: "Account" */'../../containers/Image/ImagePreviewContainer'));
const FanFavouritesContainer = lazy(() => import(/* webpackChunkName: "Account" */'../../containers/FanFavourites/FanFavouritesContainer'));
const FeaturedCarouselFullPreviewContainer = lazy(() => import(/* webpackChunkName: "AdminPreview" */'../../containers/previews/FeaturedCarouselFullPreviewContainer'));

const FandomOauthReturnContainer = lazy(() => import(/* webpackChunkName: "FandomOauthReturnContainer" */'../../containers/Auth/FandomOauthReturnContainer'));

const AdminLoginAs = lazy(() => import(/* webpackChunkName: "AdminLoginAs" */'../../containers/Auth/AdminLoginAs'));

const injectBoundary = WrappedComponent => props => (
  <ErrorBoundary>
    <WrappedComponent {...props} />
  </ErrorBoundary>
);

const noPrerender = !!window.location.hash;

const AppIntl = ({ siteTheme, siteBrand, disableMinHeight }) => {
  const { formatMessage } = useIntl();

  return (
    <Router history={history} basename="/">
      <div className={`${siteTheme === 'light' ? 'site-light-theme' : 'site-dark-theme'} brand-${siteBrand}`}>
        <Helmet>
          <meta charSet="utf-8" />
          <meta httpEquiv="content-language" content={locale} />
          <meta property="og:site_name" content="Fanatical" />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@Fanatical" />
          <meta name="twitter:creator" content="@Fanatical" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          {noPrerender &&
            <meta name="prerender-status-code" content="404" />
          }
          {noPrerender &&
            <meta name="robots" content="noindex" />
          }
        </Helmet>
        <Meta
          title={`Fanatical | ${formatMessage(messages.title)}`}
          description={formatMessage(messages.desc)}
          image="/assets/fan-og-default.jpg"
          pageType="CollectionPage"
        />
        {!isBot() &&
          <CookieConsentWrapper />
        }
        <ScrollToTop />
        <AnonymousId />

        <NavBar />

        <div className={classNames({ content: !disableMinHeight })}>
          <Suspense fallback={<SkeletonPlaceholder />}>
            <Switch>
              <Route exact path="/" component={HomePageContainer} />

              <Route exact path="/black-friday" component={BlackFriday} />
              <Route exact path="/cyber-monday" component={isSaleLive ? BlackFriday : CyberMonday} />

              <Route exact noPrerender path="/search" component={SearchPage} />
              <Route exact path="/blog" component={BlogPostsContainer} />
              <Route exact path="/blog/authors/:authorSlug" component={BlogPostsContainer} />
              <Route exact noPrerender path="/blog/preview" component={BlogPreviewContainer} />
              <Route exact path="/blog/category/:category(games|news|develop)" component={BlogPostsContainer} />
              <Route exact path="/blog/:slug" component={BlogPostContainer} />

              <Route exact path="/mac-games" component={PlatformMac} />
              <Route exact path="/linux-games" component={PlatformLinux} />
              <Route exact path="/steam-deck-games" component={SteamDeck} />
              <Route exact path="/xbox-games" component={XboxGames} />
              <Route exact path="/collections/party-games" component={HpOmenOasis} />
              <Route exact path={'/games-under/:price(5|10|20)'} component={GamesUnder} />

              <Route exact path={'/:facet(vr-games)/:facets*'} component={FacetedFacetSearch} />

              <Route exact path={'/:facet(publishers|franchises|collections|categories|games-like)/'} component={FacetOrderedList} />
              <Route exact path={'/:facet(publishers|franchises|collections|categories)/:slug'} component={FacetSearch} />
              <Route exact path={'/games-like/:slug'} component={GamesLike} />

              <Route exact path="/all-games" component={AllGames} />
              <Route exact path="/all-dlc" component={AllDLC} />
              <Route exact path="/bundle" component={BundlesContainer} />
              <Route exact path="/bundle/books" component={BundlesContainer} />
              <Route exact path="/bundle/games" component={BundlesContainer} />
              <Route exact path="/bundle/software" component={BundlesContainer} />
              <Route exact path="/bundlefest" component={BundlefestContainer} />

              <Route exact path="/top-sellers" component={TopSellers} />
              <Route exact path="/on-sale" component={OnSaleSectionsContainer} />
              <Route exact path="/rewards" component={SummerSaleContainer} />
              <Route exact path="/upcoming-games" component={UpcomingGames} />
              <Route exact path="/recently-added" component={Released} />
              <Route exact path="/new-releases" component={NewReleases} />
              <Route exact path="/latest-deals" component={LatestDealsContainer} />
              <Route exact path="/ending-soon" component={EndingSoonContainer} />
              <Route exact path="/most-wanted" component={MostWantedContainer} />
              <Route exact path="/most-gifted" component={MostGiftedContainer} />
              <Route exact path="/recently-viewed" component={RecentlyViewedPage} />
              <Route exact path="/trending-deals" component={TrendingDealsContainer} />
              <Route exact path="/gamebot" component={GameBotContainer} />
              <Route exact path="/full-price-games" component={NotOnSale} />
              <Route exact path="/flash-game-sale" component={FlashDeals} />
              <Route exact path="/dollar-deals" component={DollarDealsContainer} />
              <Route exact path="/free-to-play" component={FreeToPlayContainer} />
              <Route exact path="/fantasyverse" component={FantasyVersePage} />

              <Route exact path="/gift-cards/:filter(ea-play|nintendo|playstation|roblox|xbox|razer)?" component={GiftCards} />

              <Route exact noPrerender componentProps={{ isPreview: true }} path="/:type(game|bundle|dlc|book|comic|audio|video|software|gift-card)/preview/:id" component={ProductContainer} />
              <Route exact noPrerender path="/pick-and-mix/preview/:id" component={PickAndMixContainer} />
              <Route exact noPrerender path="/image/preview/:id" component={ImagePreviewContainer} />
              <Route exact noPrerender path="/preview/featured-carousel" component={FeaturedCarouselFullPreviewContainer} />

              <Route exact path="/(game|dlc|software)/:slug/dlc" component={ProductDLCContainer} />

              <Route exact path="/:type(game|bundle|dlc|book|comic|audio|video|software|gift-card)/:slug" component={ProductContainer} />

              <Route exact path="/pick-and-mix/:slug" component={PickAndMixContainer} />

              <Route exact path="/about-us" component={AboutUsPage} />
              <Route exact path="/affiliates" component={AffiliatesPage} />
              <Route exact path="/community" component={CommunityPage} />
              <Route exact path="/referrals" component={ReferralsPage} />
              <Route exact path="/coupons-vouchers-codes" component={VouchersContainer} />
              <Route exact path="/cookie-policy" component={CookiePolicy} />

              <Route exact path="/browser-extension" component={ExtensionPage} />
              <Route exact noPrerender path="/browser-extension/uninstall" component={ExtensionUninstallPage} />

              <Route exact path="/cart" component={Cart} />

              <Route exact path="/redeem-code" component={RedeemCodeContainer} />
              <Route exact noPrerender path="/redeem-gift" component={RedeemGiftContainer} />
              <Route exact path="/wishlist" component={WishlistContainer} />
              <Route exact noPrerender path="/wishlist/sync" component={WishlistSyncContainer} />
              <Route exact noPrerender path="/wishlist/:wishlistId" component={WishlistContainer} />
              <Route exact noPrerender path="/new-password" component={ResetPassword} />

              <Route exact noPrerender path="/fandom-auth-return" component={FandomOauthReturnContainer} />

              <Route exact noPrerender path="/billing" component={BillingContainer} />
              <Route exact noPrerender path="/payment" render={injectBoundary(Payment)} />
              <Route exact noPrerender path="/payment-return/:orderId/data" component={PaymentReturnContainer} />

              <Route exact path="/gift-guide" component={GiftGuide} />
              <Route exact path="/recommended" component={FanFavouritesContainer} />
              <Route exact path="/best-games-of-:year(2022|2023|2024)" component={HallOfFameContainer} />

              <Route exact noPrerender path="/welcome" component={WelcomePage} />
              <Route exact noPrerender path="/welcome-new-user" component={WelcomeNewUserPage} />
              <Route exact noPrerender path="/partners/hp-omen" component={HpOmenContainer} />
              <Route exact noPrerender path="/partners/hp-omen-gaming-hub" component={HpOmenGamingHub} />
              <Route exact noPrerender path="/pickaxe" component={Pickaxe} />

              <Route
                exact
                noPrerender
                path="/newconfirm/:token1/:token2/:date"
                component={AccountConfirmContainer}
              />

              <Route
                exact
                noPrerender
                path="/email-change-confirm/:token"
                component={EmailChangeConfirmContainer}
              />

              <Route exact noPrerender path="/password/reset/:token" component={PasswordResetContainer} />
              <Route exact noPrerender path="/login/magic/:token" component={MagicLoginContainer} />

              <Route exact noPrerender path="/epic-return" component={EpicReturnContainer} />

              <Route exact noPrerender path="/receipt" component={ReceiptContainer} />

              <AccountRoute exact path="/account/login" component={LoginAndSecurity} />
              <AccountRoute exact path="/account/linked-accounts" component={LinkedAccounts} />
              <AccountRoute exact path="/account/rewards" component={CouponsAndRewards} />
              <AccountRoute exact path="/account/wishlist" component={WishlistContainer} />
              <AccountRoute exact path="/account/wishlist/:wishlistId" component={WishlistContainer} />
              <AccountRoute exact path="/account/preferencecenter" component={PreferenceCenterContainer} />
              <AccountRoute exact path="/account/settings" component={WebsiteSettings} />
              <AccountRoute exact path="/account/payment" component={PaymentInformation} />
              <AccountRoute exact path="/account/contact" component={ContactPreferences} />
              <AccountRoute exact path="/account" component={AccountOverviewContainer} />
              <AccountRoute exact path="/support" component={Support} />
              <AccountRoute exact path="/orders" component={OrdersAndKeys} />
              <AccountRoute exact path="/orders/:id" component={OrderContainer} />
              <AccountRoute exact path="/orders/:id/:token1/:token2/:date" component={OrderContainer} />
              <AccountRoute exact path="/product-library" component={KeyLibrary} />
              <AccountRoute exact path="/reviews" component={OrderItemReviewsContainer} />
              <AccountRoute exact path="/review/:productId" component={OrderItemReviewForm} />

              <Route exact noPrerender path="/optin/:token" component={OptinContainer} />

              <Route
                exact noPrerender path="/throw-error/:message?"
                component={injectBoundary((errorProps) => {
                  throw new Error(errorProps.match.params.message);
                })}
              />

              <Route exact noPrerender path="/admin-login-as/:token" component={AdminLoginAs} />

              <Route exact noPrerender path="/splittest" component={SplitTestSwitch} />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </div>

        <VisibleVoucherContainer />

        <FooterGroupContainer />

        <ModalGroupContainer />

        <FeedbackPopupContainer />
      </div>
    </Router>
  );
};

AppIntl.displayName = 'AppIntl';

AppIntl.propTypes = {
  siteTheme: PropTypes.string.isRequired,
  siteBrand: PropTypes.string,
  disableMinHeight: PropTypes.bool,
};

AppIntl.defaultProps = {
  siteBrand: 'fanatical',
  disableMinHeight: false,
};

const disableMinHeightPaths = ['/welcome-new-user'];

const mapStateToProps = ({
  siteTheme,
  siteBrand,
  router: { location: { pathname } },
  // Be very careful adding connect props to App, it can cause whole App
  // to rerender and may mess with the router - See DEV-4187
}) => ({
  siteTheme,
  siteBrand,
  disableMinHeight: disableMinHeightPaths.includes(pathname),
});

const ConnectedApp = connect(mapStateToProps)(AppIntl);


function App() {
  return (
    <IntlProvider locale={locale} messages={translations}>
      <ViewportProvider>
        <ErrorBoundary>
          <ConnectedApp />
        </ErrorBoundary>
      </ViewportProvider>
    </IntlProvider>
  );
}

export default App;
